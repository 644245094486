import React from "react";
import { Provider } from "react-redux";
import { Route } from "react-router-dom"; // Import Route
import { PersistGate } from "redux-persist/integration/react";
import Routes from "./routes";
import { store, persistor } from "./store";

const App = (): React.ReactElement => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Route path="/" component={Routes} />
      </PersistGate>
    </Provider>
  );
};

export default App;
